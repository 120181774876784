import React from "react"

import Layout from "../components/shared/layout-en"
import SEO from "../components/seo"
import Benefit from "../components/benefit"
import "../components/styles/benefits.scss"

const Benefits = () => {
  let pageDescription = "Sign up to our newsletter for exclusive offers offered only to the Tea Rock Community."
  return (
  <Layout french={false}>
    <SEO title="Benefits" description={pageDescription} lang="en" />
    <Benefit french={false} />
  </Layout>
)}

export default Benefits
